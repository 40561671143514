
.container-select-course {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    gap: 20px;
    margin-top: 20px;
}



.item-lesson-presentation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 420px;
    margin-top: 20px;

}



.img-lesson-presentation {
    width: 55px;
    height: 55px;
    margin: auto 10px;

}

.item-course {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    width: 450px;
    height: 400px;
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: black;
}

.button-slow-disp-lesson {
    border: none;
    background-color: rgba(22, 199, 158, 0.7);
    border-radius: 10px;
    padding: 10px 15px;
    font-weight: bold;
}

.begin-lessons {
    color: rgb(22, 199, 158);
    font-weight: bold;
}

.item-course:hover {
    background-color: rgba(22, 199, 158, 0.2);
}


.select-course {
    background-color: rgb(22, 199, 158);
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
    color: white;
    font-weight: bold;

}

@media screen and (max-width: 980px) {
    .item-course {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }

    .container-select-course {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        gap: 20px;
        
    }
}


@media screen and (max-width: 560px) {


    .container-select-course {
        gap: 20px;
        margin-top: 30px;
        height: 100%;
    }

    .item-course {
        height: auto;
    }

    .item-lesson-presentation {
        width: 300px;
    }


    
}