.container-presentation {
    width: 100%;
    margin-top: 90px;
    
}

.first-presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    
}

.colored-zone-presentation {
    background-color: rgb(255, 242, 0);
    margin: 50px 0;
    border-radius: 10px;
    
}

.first-presentation-second {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 70px auto;
}


.first-presentation-conta-financiara {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 70px auto;
}




.first-presentation-conta-financiaara {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 70px auto;
}



[alt='calculator'] {
    width: 40%;
    margin: 30px
}


.color-presentation {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 2.5em;
    
}

.text-button-presentation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.buttons-presentation {
    padding: 10px 50px;
    width: 300px;
    margin: 10px 0;
    border: none;
    background-color: rgb(22, 199, 158);
    border-radius: 10px;
    color: white;
    font-size: 1.3em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.buttons-presentation-login {
    padding: 10px 50px;
    width: 300px;
    margin: 10px 0;
    border: none;
    background-color: rgb(255, 242, 0);
    border-radius: 10px;
    color: rgb(22, 199, 158);
    font-size: 1.3em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

}


.buttons-presentation:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}



.buttons-presentation-login:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}

.betweeb-line-presentation {
    width: 100%;
    height: 2px;
    background-color: gray;
    opacity: 0.2;
    margin: 50px 0;
}

.betweeb-line-presentation-1 {
    width: 100%;
    height: 2px;
    background-color: white;
    opacity: 0.2;
    margin: 120px 0;
}

.color-presentation-1 {
    color: rgb(22, 199, 158);
    text-align: center;
    font-size: 2.5em;
}


.color-presentation-2 {
    color: rgb(22, 199, 158);
    text-align: center;
    font-size: 1.4em;
    background-color: #16c79e;
    border-radius: 10px;
    padding: 5px;
}

[alt='exam'] {
    width: 40%;
    margin: 0px
}

.text-presentation-1 {
    width: 80%;
    margin: 20px 0;
    text-align: justify;
}


.background-part-presentation {

   
  width: 100%;
  background-color: rgb(255, 255, 255);
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 0px 0;

}

.inter-text-presentation {
    width: 90%;
    color: rgb(22, 199, 158);
}

[alt='financeanimation'] {
    width: 60%; 
}

.third-part {

    margin-top: 50px;


}

[alt='accounting'] {
    width: 40%;
    margin: 30px

}

.align-footer-presentation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

[alt='financialmanagement'] {
    width: 50%;
    margin: 0 auto;
}

@media screen and (max-width: 800px) {


    .container-presentation {
        width: 100%;
        margin-top: 30px;
        
    }

    .first-presentation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }


    .first-presentation-second {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .first-presentation-conta-financiara {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
    }

    .betweeb-line-presentation, .betweeb-line-presentation-1 {
        margin: 10px 0;
    }

    [alt='calculator'], [alt='exam'], [alt='accounting'] {
        width: 80%;
        margin: 30px
    }

    .color-presentation, .color-presentation-1 {
        width: 80%;
        font-size: 2.1em;
        text-align: center;
        margin: 0 auto;
        
    }


    .color-presentation-2 {
        width: 80%;
        font-size: 1.4em;
        text-align: center;
        margin: 0 auto;
        background-color: #16c79e;
        border-radius: 10px;
        padding: 5px;
        
    }

    [alt='financeanimation'] {
        width: 100%; 
    }


    .background-part-presentation {
          padding: 60px 0;
      
      }

      [alt='financialmanagement'] {
        width: 90%;
        margin: 0 auto;
    }
    


}