.footer {
    width: 100%;
    background-color: #18171d;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
   
    
}

.footer p {
    color: white;
}



.footer-menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0;
}


.facebook {
    width: 25px;
    height: 25px;
    padding: 5px;
    background-color: transparent;
    color: white;
    margin: 3px 0 0 0;
   align-self: center; 
   cursor: pointer;
  
}

.border-social-facebook {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 12px solid rgb(1, 101, 225);
    border-radius: 50%;
    margin: 0 20px;
    cursor: pointer;
    background-color: rgb(1, 101, 225);
}



.item-fooyer-menu {
    color: white;
    margin: 10px 20px;
    cursor: pointer;
    opacity: 0.6;
}



.instagram {
    width: 25px;
    height: 25px;
    
    padding: 5px;
    background-color: transparent;
    color: white;
    margin: 3px 0 0 0;
    align-self: center;
    cursor: pointer;
  
    
}

.border-social-instagram {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 12px solid rgb(193, 53, 132);
    border-radius: 50%;
    margin: 0 20px;
    cursor: pointer;
    background-color: rgb(193, 53, 132);
}




.linkedin {
    width: 25px;
    height: 25px;
    padding: 5px;
    background-color: transparent;
    color: white;
    margin: 3px 0 0 0;
    cursor: pointer;
    
}

.border-social-linkedin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 12px solid rgb(10, 102, 194);
    border-radius: 50%;
    margin: 0 20px;
    cursor: pointer;
    background-color: rgb(10, 102, 194);
}



.links-footer {
    text-decoration: none;
    
}

.item-fooyer-menu:hover {
    opacity: 1;
}

.reserved-rights {
    color: rgb(255, 242, 0);
    text-decoration: none;
    cursor: pointer;

}

.general-container-footer-info {
    height: 100vh;
}
.info-footer-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 30px auto;
    padding: 12px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.info-footer-container h3 {
    align-self: start;
}




@media screen and (max-width: 700px) {

    .footer-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .general-container-footer-info {
        height: auto;
    }

    .info-footer-container {
        width: 90%;
      
     }
}