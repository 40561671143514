.align-header-elements {
   
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
height: auto;
    background-color: rgb(255, 255, 255);
    padding: 25px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    

}

.image-user-central {
    display: block;
    width: 50px; /* Ajustează dimensiunea imaginii utilizatorului */
    height: 50px;
    border-radius: 50%; /* Dacă vrei să fie o imagine rotundă */
    border: 1px solid rgb(22, 199, 158);
    cursor: pointer;
   
}

.container-user-menu {
    display: block;
    position: absolute;
    right: 60px;
  

}

.menu-user-icon {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    right: 25px;
    top: 75px;
    border-radius: 10px;
    background-color: rgb(22, 199, 158);
    padding: 10px 20px 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1); 
    width: 200px;
}

.menu-user-icon-hide {
    display: none;
}


.item-user-menu-icon {
    font-family: 'trebuchet MS';
    font-weight: bold;
    color: rgb(0, 0, 0);
    border: none;
    background-color: rgb(22, 199, 158);
    text-decoration: none;   
    margin: 10px 0;
}

.item-user-menu-icon:hover {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

.user-picture-name {
    margin-right: 20px;
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    gap: 5px;
   
    
    
}





@media screen and (max-width: 1090px) {
    .image-user-central {
        display: none;
    }

    .container-user-menu {
        display: none;
    
    }


    .align-header-elements { 
        height: 115px;
    }

   
   
    }