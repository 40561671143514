.general-lesson-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: rgb(22, 199, 158);
    margin: 0 auto;
    position: relative; /* Asigură poziționarea relativă */
}

.header-lesson {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 700px;
    margin: 0 auto;
}

.left-arrow-back-lesson {
    color: white;
    font-size: 30px;
    margin: 0 20px;
}

.menu-lessons-right {
    color: white;
    font-size: 40px;
    margin: 0 20px;
    cursor: pointer; /* Cursor pointer pentru indicația clicului */
}

.profile-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #f0f0f0;
    overflow: hidden;
  }
  
  .profile-avatar .profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .default-profile-icon {
    color: #2e3a59; /* Culoare pentru icon */
  }

  

.submenu-lesson {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px; /* Începe de sub buton */
    right: 0; /* Se aliniază la dreapta */
    width: 280px; /* Dimensiune specifică pentru vizibilitate */
    background-color: rgb(22, 199, 158);
    border-radius: 0 0 10px 10px;
    padding: 10px 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border-top: 1px solid white;
}

.orientation-menu-lesson {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}


.elements-lesson {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgb(22, 199, 158);
    width: 100%;
    padding: 10px;
  
}


.elements-lesson div,
.elements-lesson a {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 10px 15px;
    margin: 5px 0;
    color: white;
    background-color: rgb(22, 180, 150);
    border-radius: 8px;
    cursor: pointer;
    text-decoration: none;
}



.elements-lesson div:hover,
.elements-lesson a:hover {
    background-color: rgb(18, 150, 120);
    
}

.elements-lesson div {
    font-weight: bold;
}

.elements-lesson p {
    font-weight: normal;
}



.orientation-menu-lesson {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
}

.icon-with-value {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .icon-value {
    font-size: 14px;
    color: white;
    margin: auto;
  }
  

  .custom-sound {
    color: rgb(22, 199, 158);
    background-color: white;
    border: 1px solid white;
    border-radius: 5px;
    padding: 0 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
  }

@media screen and (max-width: 700px) {
    .submenu-lesson {
      
        width: 100vw; /* Dimensiune specifică pentru vizibilitate */
    
    }
   
} 