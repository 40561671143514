.settings-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 90vw;
  height: 100vh;
  border-radius: 20px;
  padding: 0 20px;
    background-color: white;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

}

.set-block-pass-title {
  font-weight: bold;

}

.settings-dashbord {
    display: flex;
    flex-direction: column;;
    align-items: flex-start;
    padding: 20px 0px 0 0px;
    width: 40%;
    margin: 0 auto;
    border-right: 1px solid gray;
    height: 100vh;
}

.set-block-pass {
  width: 100%;
    border: none;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgb(22, 199, 158);
    padding: 12px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.details-settings {
  
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
}

.buttons-menu-settings {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 10px;
}

.button-change-pcture {
  background-color: #16c79e;
  border: none;
  color: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
}


.plan-details-table {
  width: 95%;
  max-width: 600px;
  margin: 20px auto;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid red;
}

.plan-details-table tr {
  padding: 10px;
}

.plan-details-table td {
  border: 1px solid rgba(180, 180, 180, 0.4);
  padding: 10px;
  width: 50%;
}


.distance-table-plan {
  padding: 20px;
}


.button-descarca-factura {
  background-color: #16c79e;
  border: none;
  color: #fff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
}




.image-profile-change {
  display: flex;
  align-items: center;
}



.distance-image-profile {
  margin-right: 20px;
}

.change-image-user {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #16c79e;
}

.button-upload, .button-set, .button-delete {
  background-color: #16c79e;
  border: none;
  color: #fff;
  margin: 10px 10px;
  padding: 10px;
  width: 100px;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);

}


.set-block-password {
  width: 100%;
  border: none;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgb(22, 199, 158);
  padding: 12px 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
      0 6px 20px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  margin: 0 10px 0 0;
}


.align-passwords {
  display: flex;
  flex-direction: row;
}

.show-pass-button {
  border: none;
  border-radius: 10px;
}

.eye-icon {
  cursor: pointer;
  margin: 0 auto;
  color: #16c79e;
  transition: color 0.3s ease;
}

.eye-icon:hover {
  color: #0056b3;
} 

.arrow-submenu-user-items {
  display: none;
}


.show-mobile-version-sumbenu-user {
  display: none;
}

.button-edit-user-profile-desktop-hide {
  display: none;
}

.form-change-image {
  display: flex;
  flex-direction: column;

  width: 95%;
  max-width: 600px;
  margin: 20px auto;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;


}

.button-edit-user-profile-desktop-show {
  display: block;
  margin: 0px 0;
  padding: 10px;
  cursor: pointer;
  border: none;
  font-weight: normal;
  width: 100%;
  color: black;
  border-top: 1px solid black;
  text-align: left;
  background-color: white;
}

.button-edit-user-profile-desktop-show.active {
  font-weight: bold;
  color: #16c79e; /* Culoare pentru a evidenția butonul activ */
 
}


.button-edit-user-profile-desktop-show-title {
  display: block;
  margin: -20px 0 0 0;
  padding: 20px;
  cursor: pointer;
  border: none;
  font-weight: bold;
  width: 100%;
  color: black;
  background: none;
font-size: 20px;
  text-align: left;
}


.width-submenu-elements {
  width: 95%;
  max-width: 600px;
  margin: 20px auto;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

.button-close-account {
  background-color: #16c79e;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px #0000001a, 0 6px 20px #0000001a;
  color: #fff;
  font-weight: 700;
  margin: 10px 0;
  padding: 10px;
}

.width-submenu-elements-plan {
  margin: 30px 0;
}

#set-important-submenu {
  display: none;
}

#set-important-submenu-second {
  display: block;
}


@media screen and (max-width: 1030px) {

.button-upload, .button-set, .button-delete {
  background-color: #16c79e;
  border: none;
  color: #fff;
  margin: 10px 1px;
  padding: 10px;
  width: auto;
  border-radius: 10px;
  font-weight: 700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);

}
}



@media screen and (max-width: 600px) {


  #set-important-submenu {
    display: block;
  }

  #set-important-submenu-second {
    display: none;
  }

    .settings-section {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border: none;
        margin-top: 20px;
        border-radius: 0px;
        padding: 20px 0;
    }




    .details-settings {
        position: absolute;
        width: 100%;
        background-color: white;
        align-self: flex-end;
        top: 142px;
        height: 100vh;
        display: block;
       
    
    }

    .settings-dashbord {
        height: 100vh;
        width: 100%;
        padding: 0px 0;
        margin: -25px 0 0 0;
        border-right: none;
    }

    .buttons-menu-settings {
        display: flex;
        flex-direction: column; /* Așază butoanele pe verticală */
        justify-content: start; /* Spațiu între butoane */
        height: 100%; /* Asigură că ocupă înălțimea completă a containerului */
     
      }
      
      .button-edit-user-profile-mob {
        display: flex;
        justify-content: space-between; 
        text-align: center; 
        background-color: white;
        width: 100%; 
        box-sizing: border-box; 
        margin: 0px auto;
        padding: 20px 20px;
        border-bottom: 1px solid gray; 
      }
      

      .button-exit-change-menu-profile {
        display: flex; 
        background-color: white;
        width: 100%; 
        box-sizing: border-box; 
        margin: 0px auto;
        padding: 30px 20px;
        text-align: center;
        border-bottom: 1px solid gray; 
        margin-top: 30px;
      }

     

      .button-exit-change-menu-profile h4 {
        margin: 0px auto;
      }

      .size-arrow-mob-user {
        width: 30px;
        height: 30px;
        cursor: pointer;
        
      }


      .button-close-account {
        background-color: #16c79e;
        border: none;
        border-radius: 10px;
        box-shadow: 0 4px 8px #0000001a, 0 6px 20px #0000001a;
        color: #fff;
        font-weight: 700;
        margin: 10px 0;
        padding: 10px;
      }
      
      .button-close-account:hover {
        background-color: #c9302c;
      }

      
      .sub-arrow-info {
        display: flex; 
       
        background-color: white;
        width: 100%;
        box-sizing: border-box; 
        border-bottom: 1px solid gray;
        top: -20px;
        padding: 34px 20px;
      }
      

      .arrow-submenu-user-items {
        display: block;
      }

      .show-mobile-version-sumbenu-user {
        display: block;
      }

      .button-edit-user-profile-desktop-hide {
        display: block;
        cursor: pointer;
       
       
      
      }
      
      .button-edit-user-profile-desktop-show {
        display: none;
      }


      .button-edit-user-profile-desktop-show-title {
        display: none;
      }


      .container-mob-menu-width {
        position: absolute;
        width: 100%;
        left: 0px;
        right: 0;
        top: -10px;
      }

      .width-submenu-elements {
        margin: auto;
        width: 90%;
        padding: 20px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
      }


      .button-upload, .button-set, .button-delete {
        background-color: #16c79e;
        border: none;
        color: #fff;
        margin: 10px 1px;
        padding: 10px;
        width: auto;
        border-radius: 10px;
        font-weight: 700;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1);
      
      }


}