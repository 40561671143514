.user-background-info {
    background-color: white;
    height: 100%;
}

#user-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #18171d;
    padding: 50px 0;
}

.default-profile-icon {
    background-color: white;
    border-radius: 50%;
    color: black;
    height: 50px;
    margin: auto;
    padding: 10px;
    width: 50px;
  }

.user-image img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 2px solid white;
    margin: 0 10px;
}

.data-color-user {
    color: white;
}

.user-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.user-datas-dashbord {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 20px;
}



.user-info-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 20px;
}

#lectii-certificate {
    
    margin: 0 5px;
    
}

#punctaj-user {
    background-color: rgb(22, 199, 158);
    width: 300px;
    height: auto;
    padding: 10px 10px;
    border-radius: 10px;
    margin: 0 5px;
}

.size-item-dashbord {
   margin: auto 0;
    color: white;
    font-size: 30px;
}

.item-dashbord {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
}

.item-info-user-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(22, 199, 158);
    width: 600px;
    height: auto;
    border-radius: 10px;
    margin: 10px 0;
    padding: 30px 0;

}

.item-info-user {
    background-color: rgb(22, 199, 158);
    width: 300px;
    height: auto;
    border-radius: 10px;

}

.cadru-certificate {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
}

.cadru-item-certificate-baze {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: rgb(255,163, 16);
}


.cadru-item-certificate-financiara {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: rgb(171,136, 243);
}

.cadru-item-certificate-raportare {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    background-color: rgb(114, 162, 227);
}

.item-certificate {
    width: 50px;
    height: 50px; 
}

.point-certificate-1 {
    width: 10px;
    height: 10px;
    background-color: white;
    margin: 0 2px;
    border-radius: 50%;
    border: 1px solid white;
}

.point-certificate-2 {
    width: 10px;
    height: 10px;
    margin: 0 2px;
    border-radius: 50%;
    border: 1px solid white;
}

.download-cert {
    border: none;
    font-size: 12px;
    width: 40px;
    background-color: white;
}

.text-diploma {
    font-size: 12px;
    color: white;
    text-align: center;
}

.align-dashbord-elements {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
width: 600px;
}




.cadru-progres-dashbord-baze {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 25px 0;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgb(255,163, 16);
    margin: 10px 0;
    text-decoration: none;
}

.cadru-progres-dashbord-financiara {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 25px 0;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgb(171,136, 243);
    margin: 10px 0;
}

.cadru-progres-dashbord-raportare {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    padding: 25px 0;
    border: 1px solid white;
    border-radius: 10px;
    background-color: rgb(114, 162, 227);
    margin: 10px 0;
}

.cadru-puncte-dashbord-candh {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    padding: 0 10px;
    height: 35px;
    background-color: white;
    margin: 0 15px;
    border-radius: 10px;

}

.buton-medalii {
    margin: 0 15px;
    background: none;
    border: none;
    border: 1px solid white;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

}


@media screen and (max-width: 600px) {
    .user-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .user-info-page {
    height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 20px;
    }

    .item-info-user {
        width: 90vw;    
    }

    #punctaj-user {
        width: 90vw;
    }


    .align-dashbord-elements {
        width: 100%;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
        }

        .item-info-user-one {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: rgb(22, 199, 158);
            width: 100%;
            height: auto;
            border-radius: 10px;
            padding: 30px 0;
        
        }


        .user-datas-dashbord {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
}