
@keyframes moveButton {
    0% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(-3px);
    }
}



.objects-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.start-button {
    border: none;
    padding: 20px 40px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(22, 199, 158);
    font-size: 20px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    animation: moveButton 1.4s infinite alternate;
    border: 1px solid rgb(167, 167, 167);
    text-decoration: none;
    z-index: 2;
    margin-bottom: -15px;
}




.start-button::after {
    content: "";
    position: absolute;
    bottom: -40px; /* Ajustează în funcție de dimensiunea triunghiului */
    left: 50%;
    transform: translateX(-50%);
    border-width: 20px; /* Dimensiunea triunghiului */
    border-style: solid;
    border-color: rgb(167, 167, 167) transparent transparent transparent; /* Triunghiul exterior */
    z-index: 1;
}

.start-button::before {
    content: "";
    position: absolute;
    bottom: -37px; /* Ajustează poziția triunghiului interior */
    left: 50%;
    transform: translateX(-50%);
    border-width: 19px; /* Triunghi interior mai mic */
    border-style: solid;
    border-color: #fff transparent transparent transparent; /* Culoarea interioară a triunghiului */
    z-index: 2;
}


.start-button:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}

.new-lesson {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}



