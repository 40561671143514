.blog-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.half-blog-post {
    height: 50%;
}

.image-blog {
    background-image: url('../imagesHome/blog1.png');
    background-size: cover; /* Face ca imaginea să acopere întregul spațiu */
    background-position: center; /* Centrează imaginea */
    background-repeat: no-repeat;
    max-width: 450px;
    width: 100%;
    margin: 20px;
    height: 320px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.align-content-post {
    margin: 20px;
}

.number-blog-page {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin: 30px 0;
}

.item-number-blog-page {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(22, 199, 158);
    padding: 3px 20px 3px 20px;
    color: white;
    border-radius: 5px;
}



@media screen and (max-width: 700px) {

    .image-blog {
      
        height: 270px;
      
    }
}


@media screen and (max-width: 600px) {

    .blog-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .image-blog {
        width: 85%;
        margin: 20px;
        height: 330px;
        border-radius: 10px;
    }

}