.general-diploma {
display: flex;
flex-direction: column;
justify-content: start;
align-items: center;
height: 100vh;
margin-top: 50px;
}


.diploma-container {
    width: 80%;
    height: 600px;
    border: 5px solid #000;
    padding: 50px;
    text-align: center;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    position: relative;
}

.diploma-container h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.diploma-container h2 {
    font-size: 28px;
    color: #4CAF50;
}

.diploma-container h3 {
    font-size: 24px;
    margin-top: 10px;
}

.logo-diploma {
    width: 20%;
}
