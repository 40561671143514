:root {
    --progress-color: #4caf50; /* O nuanță verde mai deschisă */
    --background-color: #d3d3d3; /* Gri deschis pentru fundal */
    --thumb-color: white;
    --thumb-border-color: #4caf50;
    --progress-bar-height: 10px;
    --thumb-size: 16px;
}

.general-section-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgb(22, 199, 158); /* Fundal verde similar */
    padding: 10px;
    border-radius: 8px;
}

.section-progress-bar-lesson {
    display: flex;
    align-items: center;
    width: 80%;
    
}

.first-elements-header-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto 25px auto;
}

.progress-bar-container {
    width: 100%;
    height: var(--progress-bar-height);
    background-color: var(--background-color);
    border-radius: 10px;
    position: relative;
}

.progress-bar-range {
    background-color: white;
    height: 20px;
    position: absolute;
    width: 20px;
    z-index: 20;
    border-radius: 50px;
    border: 1px solid #16c79e;
 
}

.progress-bar-background {
  background-color: #fff200;
  height: 12px;
  position: absolute;
  border-radius: 50px 0px 0px 50px;
  border: 1px solid #16c79e;
  left: 0px;

}

.arrow-change-lesson-x {
    color: white;
    cursor: pointer;
    font-size: 35px;
}

.coin-score-box {
    background-color: #fff;
    color: #000;
    margin: 0 2px;
    padding: 5px 10px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: relative; /* necesar pentru a poziționa pseudo-elementul */
  }
  
  .coin-score-box::after {
    content: "";
    position: absolute;
    right: -8px; /* ajustează în funcție de cât de departe vrei să fie față de casetă */
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid #fff; /* culoarea săgeții, aceeași ca backgroundul casetei */
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
  
  .coin-edit-score {
    font-size: 1.5rem; /* ajustează dimensiunea icoanei */
    color: #ffd700;
    margin: 0 8px;
 
  }

.dim-prog-bar-1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 30%;
    margin: 0 auto;
}

.dim-prog-bar-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 30%;
    margin: 0 auto;
}

.dim-prog-bar-3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 30%;
    margin: 0 auto;
}

.exit-lesson-name {
    display: flex;
    align-items: center;
    margin-left: 10px;
}





.eye-container {
  position: relative;
  display: inline-block;
  width: 32px; /* Ajustează dimensiunea după cum ai nevoie */
  height: 32px;
  margin: 0 5px;
}

.eye-icon {
 
  font-size: 32px; /* Dimensiunea inimii, ajustabilă */
  color: rgb(255, 255, 255);/* Poți schimba culoarea */
}

.heart-container {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.heart-icon {
  font-size: 36px; /* Ajustează dimensiunea inimii după preferințe */
  color: #FF6F61; /* Poți schimba culoarea */
}

.score-text {
  display: flex;
  justify-content: center;
  align-items: center;
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Contrast pentru a fi vizibil */
  font-weight: bold;
  font-size: 16 px; /* Ajustează mărimea în funcție de spațiu */
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #FF6F61; /* Fundalul pentru număr */
  pointer-events: none;
  
}

  

  /* Poziționăm elementul principal și bara de progres */
.general-section-progress-bar {
    position: relative;
  }
  
  /* Efectul de bounce pentru iconița monedelor */
  .bounce-animation {
    animation: bounce 0.3s ease-out; /* Durata animației */
  }
  
  /* Animația de mărire temporară */
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3); /* Mărire temporară */
    }
    100% {
      transform: scale(1); /* Revine la dimensiunea inițială */
    }
  }
  

  


@media screen and (max-width: 800px) {
    .section-progress-bar-lesson {
        width: 90%;
    }
    
}
