.logo {
    width: 40%;
    max-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
   text-align: center;
}

.logo img {
    width: 100%;
}


@media screen and (max-width: 1090px) {

    .logo {
        width: 250px;
        max-width: 100%;
        position: absolute;
        left: 0;
        top: 10px;
    }

    .logo img {
        
        width: 75%;
    }

    
}


@media screen and (max-width: 500px) {

   

    .logo img {
        
        margin: 0 0 0 0px;
    }

    
}




