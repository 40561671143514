
.menu-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 900px; 
}



.default-profile-icon-submenu {
    background-color: #fff;
    border: 2px solid #000;
    border-radius: 50%;
    color: #000;
    height: 40px;
    margin: auto 10px auto auto;
    width: 40px;
  }
  

  .image-profile-user {
    margin: 5px auto;
    width: 25px;
    height: 25px;

  }
  .user-image-general-menu {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  


.name-menu-item {
    width: 120px;
    font-family: 'trebuchet MS';
    font-weight: bold;
    color: rgb(0, 0, 0);
    background-color: rgb(22, 199, 158);
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1); 
    margin: 0 10px;
}


.name-menu-item-desktop-premium {
    width: 135px;
    font-family: 'trebuchet MS';
    font-weight: bold;
    color: rgb(0, 0, 0);
    background-color: #ffc107;
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1); 
    margin: 0 10px;
}


.login-item-desktop {
    width: 120px;
    font-family: 'trebuchet MS';
    font-weight: bold;
    color: rgb(22, 199, 158);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1); 
    margin: 0 10px;
    border: 1px solid rgb(22, 199, 158);
}

.signup-item-desktop {
    width: 120px;
    font-family: 'trebuchet MS';
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: rgb(22, 199, 158);
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1); 
    margin: 0 10px;
}




.premium {
    margin: 0 10px;
}

.premium:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}


.name-menu-item:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}

.name-menu-item-selected  {
    font-family: 'trebuchet MS';
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
}

.reg-log {
    width: 55px;
    background-color: rgb(22, 199, 158);
    border-radius: 50px;
    
}

.mob-menu-open {
    display: none;
} 
.mobile-menu {
    display: none;

}

.name-menu-item-mob {
    display: none;
}

.name-menu-item-mob-premium {
    display: none;
}


.form-menu-tabel {
    display: none;

}

.user-menu-button {
    display: none;
}


.show-second-menu {
    display: none;
    
}

.hide-second-menu {
    display: none;
    
}


@media screen and (max-width: 1090px) {
    .menu-items {
        display: none;
    }

   

    .mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 60px;
        position: absolute;
        right: 30px;
        top: 40px;
       
   
        cursor: pointer;
        background-color: white;
    }

    .item-mobile-ball {
        width: 12px;
        height: 12px;
        background-color: rgb(22, 199, 158);
        border-radius: 50%;
        margin: 3px 0;
        transform: translateX(15px);
        transition-duration: 0.5s;

    }


    .item-mobile-ball-open {
        width: 45px;
        height: 6px;
        background-color: rgb(22, 199, 158);
        border-radius: 50px;
        margin: 4px 0;
        transform: translateX(0px);
        transition-duration: 0.5s;
    }
    
    .name-menu-item-mob {
        display: block;
        background-color: rgb(22, 199, 158);
        border-radius: 10px;
        font-family: 'trebuchet MS';
        color: rgb(47, 47, 47);
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
        padding: 10px 10px;
        margin: 10px auto;
        width: 90%;
    }


    .name-menu-item-mob-premium {
        display: block;
        background-color: #ffc107;
        border-radius: 10px;
        font-family: 'trebuchet MS';
        color: rgb(47, 47, 47);
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
        padding: 10px 10px;
        margin: 10px auto;
        width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    }



    .name-menu-item-mob:hover {
        
    
      
    }

    .mob-menu-open {
        position: absolute;
        top: 115px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 100%;
        height: 400px;
        padding: 10px 0px 10px 0px;
        z-index: 10;
        user-select: none;
        border-radius: 0 0 15px 15px;
        border-top: 1px solid rgb(22, 199, 158);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    }

   

    .mob-menu-open-none {
        display: none;
    }

    .credentials-forms {
        width: 100%;
    }

    .form-menu-tabel {
        text-align: center;
        display: block;
        font-family: 'trebuchet MS';
        color: rgb(255, 255, 255);
        text-decoration: none;
        padding: 10px 10px;
        margin: 10px auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1); 
        width: 90%;
        font-size: 20px;
        font-weight: bold;
    }


    .design-ligin {
        color: rgb(22, 199, 158);
        background-color: white;
        border: 1px solid rgb(22, 199, 158)
    }

    .design-inregistrare {
        color: rgb(255, 255, 255);
        background-color: rgb(22, 199, 158);
        border: 1px solid rgb(22, 199, 158);

    }

    .design-logout {
            color: rgb(255, 255, 255);
            background-color: rgb(22, 199, 158);
            border: 1px solid rgb(22, 199, 158);
    }



    .user-menu-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'trebuchet MS';
        color: rgb(0, 0, 0);
        text-decoration: none;
        border: none;
        padding: 10px 10px;
        margin: 10px auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1); 
        width: 90%;
        font-size: 20px;
        font-weight: bold;
    }

    .user-image-general-menu {
        width: 35px;
        height: 35px;
        margin: 0 15px;
        border-radius: 20px;
    }

    .user-submenu-elements {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-sumbenu-user {
        margin-right: 20px;
    }


    .hide-second-menu {
        display: none;

    }

    .show-second-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        
    }


    .name-menu-item-mob-back {
        display: block;
        font-family: 'trebuchet MS';
        color: rgb(47, 47, 47);
        font-size: 20px;
        font-weight: bold;
        text-decoration: none;
        padding: 10px 10px;
        margin: 10px auto;
        width: 90%;
    }

    .name-menu-item-mob-back:hover {
        color: rgb(22, 199, 158);
        
        
    }
    
}


