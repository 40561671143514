.progres-lesson {
    width: 90%;
    height: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}



.procent-lesson-show-lwsson {
    margin-top: -11px;
    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid rgb(22, 199, 158);
    display: flex;
    justify-content: center;
    align-items: center;
    margih: auto;
    border-radius: 50%;
    font-size: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}