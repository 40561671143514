.lessons-general-container {
    margin-top: 50px;
    height: 100vh

}

.progress-bar-container {
    display: flex;
    flex-direction: ROW;
    justify-content: center;
    align-items: center;
}

.progress-bar {
   
    width: 80%;
    height: 25px;
    background-color: rgb(222, 222, 222);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    border: 1px solid rgb(22, 199, 158); 
}

.light-bar {
    position: relative;
    top: 5px;
    left: 2%;
    width: 95%;
    height: 4px;
    border-radius: 20px;
    background-color: rgba(222, 222, 222, 0.306);
    transition-duration: 1.5s;

}

.questions {
    width: 70%;
    text-align: center;
    align-items: center;
    margin:20px auto 0 auto;
    
}

.question-text {
    width: 90%;
    margin: 0 auto;
    text-align: left;
   
    
}

.line-question {
    width: 100%;
    height: 2px;
    background-color: #7575755d;
    margin: 10px 0;
}

.answers {
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.answer-button {
    margin: 8px 0;
    width: 100%;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: rgb(93, 94, 94);
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    border-radius: 20px;
    background-color: #ffffff; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
                0 6px 20px rgba(0, 0, 0, 0.1); 
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}


.answer-button-selected {
    margin: 8px 0;
    width: 100%;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    color: rgb(93, 94, 94);
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    border-radius: 20px;
    background-color: rgb(22, 199, 158);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
                0 6px 20px rgba(0, 0, 0, 0.1); 
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}


.color-message-green {
    color: rgb(22, 199, 158);
    background-color: rgb(255, 255, 255);
    width: 70%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 10px 0;
    
    text-align: center;
 
}

.color-message-red {
    color: red;
    background-color: rgb(255, 255, 255);
    width: 70%;
    border-radius: 20px;
    margin: 0 auto;
    padding: 10px 0;
   
    text-align: center; 
}


.continue-button-none {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    color: rgb(213, 213, 213);
    background-color: rgb(172, 172, 172);
    width: 500px;
    border: none;
    border-radius: 20px;
    padding: 15px 0;
    font-size: 22px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    text-align: center; 
    cursor: pointer;
}

.continue-button {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    color: rgb(22, 199, 158);
    background-color: rgb(255, 242, 0);
    width: 500px;
    border: none;
    border-radius: 20px;
    padding: 15px 0;
    font-size: 22px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    text-align: center; 
    cursor: pointer;
}


.continue-button-wrong {
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    color: white;
    background-color: rgb(255, 0, 0, 0.5);
    width: 500px;
    border: none;
    border-radius: 20px;
    padding: 15px 0;
    font-size: 22px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
    text-align: center; 
    cursor: pointer;
}



.continue-button:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2); 
    cursor: pointer; 
}

.continue-button:active {
    transform: translateY(-3px); 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2); 
    cursor: pointer; 
}

.confetti-container {
    position: absolute;
    top: 200px;
}

.confetti-image {
    width: 100vw;
    height: 60vh;
}


[alt='arrowExit'] {
    width: 25px;
    margin: 10px;
    padding: 4px;
}

[alt='arrowExit']:hover {
    background-color: gray;
    border-radius: 20px;
    padding: 4px;
}

[alt='arrowExit']:active {
    background-color: rgb(255, 242, 0);
}

#move-lesson-button {
    margin-top: 10px;
    padding: 8px 0
}

@media screen and (max-width: 800px) {
    
.continue-button-none {
  
    width: 400px;
    
}
}

@media screen and (max-width: 650px) {
    
    .lessons-general-container {
        height: 100%;
    
    }


    .progress-bar {
        width: 80%;
    }

    .continue-button-wrong {
        width: 300px;
    }

    .continue-button-none {
        width: 300px;
    }

    .continue-button {
        width: 300px;
    } 

    .questions {
        width: 90%;
       
        
    }
    .question-text {
        width: 90%;
    }


    }
