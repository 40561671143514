

.general-cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 400px;
  padding: 0 0 150px 0;
  height: 100vh;
}

.item-offert {
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 40%;

}

.text-title-price-premium {
  color: #ffc107;
}


.text-title-price-free {
  color: #16c79e;
}
.items-card-offer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.img-price {
  margin-top: 30px;
  width: 80%;
  height: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

}

.button-offer {
  border: none;
  background-color: rgb(22, 199, 158);
  color: black;
  width: 190px;
  padding: 10px 0px;
  border-radius: 5px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.button-offer-premium {

  border: none;
  background-color: #ffc107;
  width: 190px;
  padding: 10px 0px;
  border-radius: 5px;
  font-weight: bold;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.item-x {
  color: red;
}

.item-bifa {
  color: green;
}

.item-space-offer {
  margin: 15px 0;
}


@media screen and (max-width: 800px) {
  .general-cards {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    height: 100%;
  }



  .item-offert {
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 85%;
  
  }
  }
