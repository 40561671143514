.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f4f4f4;
    padding: 1em;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .cookie-consent-banner button {
    padding: 0.5em 1em;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 0.5em;
  }
  
  .cookie-consent-banner button:hover {
    background-color: #45a049;
  }
  