
.info-cadru-premium {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    margin: 1em auto 0 auto;
    border-radius: 10px;
    padding-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.premium-second-page {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
}

.cadru-premium-step-two {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.button-premium-step-two {
    border: none;
    background-color: #ffc107;
    width: auto;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin: 10px;
    color: black;
   

}


.button-premium-see-plans {
    border: none;
    background-color: #16c79e;
    width: auto;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin: 10px;
    color: black;
   

}

.button-billing {
    border: none;
    background-color: #ffc107;
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
   

}

.input-billing {
    width: 390px;
    height: 40px;
    border: 1px solid rgb(22, 199, 158);
    border-radius: 10px;
    margin: 10px 0;
    padding: 0 10px;
    font-size: 18px;
}


.offer-button-off {
    border: none;
    background-color: #ffc107;
    width: 85%;
    padding: 15px 0;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    color: black;
}

.button-try-now {
    border: none;
    background-color: rgb(22, 199, 158, 0.2);
    width: 85%;
    padding: 15px 0;
    border-radius: 5px;
    border: 1px solid rgb(22, 199, 158);
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    color: black;
}



.header-premuim-page {
    background-color: rgb(22, 199, 158);
    width: 100%;
    text-align: center;
    padding: 20px 0;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    
}

.header-premuim-page h1 {
    color: white;
    
}

.header-prem-top {
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.header-premium {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;
    margin: 0 auto;
    
}

.logo-premuim {
    width: 180px;
    cursor: pointer;
   
}

.exit-premium {
    width: 40px;
    height: 40px;
    cursor: pointer;
    color: rgb(22, 199, 158);
}

.logo-dist-premium {
    padding: 20px;
}

.x-dist-premium {
    padding: 20px;
}

.premium-third-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.size-text-offer {
    width: 95%;
}
.plan-price-one {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffc107;
    padding: 10px 10px;
    text-align: center;
    margin: 20px auto;
    border-radius: 10px;
    background-color: #16c79e;
    color: white;
}

.plan-price {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    text-align: center;
    margin: 10px auto;
    border-radius: 10px;
}


.plan-label-price {
    width: 95%;
}


.plan-label-price-best {
    width: 98%;
}


.plan-price-price-plan {
    width: 95%;
    display: flex;
    flex-direction: row;
    border: 3px solid #ffc107;
    justify-content: center;
    align-items: center;
    padding: 10px 15px; /* Mai mult padding */
    margin: 3px auto 15px auto; /* Mai mult spațiu între planuri */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Umbre pentru un efect de card */
    transition: transform 0.3s ease-in-out;
    background-color: #16c79e; /* Asigură fundal alb */
    color: white;
}


.plan-price-price-plan-best {
    width: 95%;
    display: flex;
    flex-direction: row;
    border: 3px solid #16c79e;
    justify-content: center;
    align-items: center;
    padding: 10px 15px; /* Mai mult padding */
    margin: 3px auto 15px auto; /* Mai mult spațiu între planuri */
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Umbre pentru un efect de card */
    transition: transform 0.3s ease-in-out;
    background-color: #ffc107; /* Asigură fundal alb */
    color: white;
}


.plan-label {
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.plan-checkbox {
    margin-right: 15px; /* Spațiu între checkbox și card */
    width: 20px;
    height: 20px;
    accent-color: #16c79e; /* Schimbă culoarea checkbox-ului */
}



.details-price {
    text-align: left;
    width: 50%

}

.details-price-price {
    width: 100%; /* Ajustează pentru echilibru */
    text-align: center;
}


.align-plans-elements {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Spațiere egală */
    align-items: center;
    margin: auto;
}

.line-price {
    width: 2px;
    height: 60px; /* Linii mai lungi */
    background-color: #ffc107;
    margin: 0 1px;
}

.line-price-best {
    width: 2px;
    height: 60px; /* Linii mai lungi */
    background-color: #16c79e;
    margin: 0 1px;
}


.css-link-offer {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    text-decoration: none;
    
}

.view-plans-link {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  

}


.billing-style {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-billing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.growth-text-price {
    font-size: 2.5em;
}


.growth-text-price-plans {
    font-size: 1.8em;
}

.growth-text-price-plans-best {
    font-size: 2.5em;
}

@media screen and (max-width: 1000px) {
    .info-cadru-premium {
        width: 70%;
    }

    .header-premium {
        width: 70%;
        margin: 0 auto;
    }

}


@media screen and (max-width: 800px) {
    .info-cadru-premium {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
       
        bottom: 10px;
        width: 95%;
      
    }

    .header-premium {
        width: 95%;
        margin: 0 auto;
    }

    .cadru-premium-step-two {
        display: flex;
        justify-content: center;
        align-items: center;
    }


  


    .input-billing {
        width: 300px;
        height: 40px;
        border: 1px solid rgb(22, 199, 158);
        border-radius: 10px;
        margin: 10px 0;
        padding: 0 10px;
        font-size: 18px;
    }

    }
  