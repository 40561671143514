.container-ajotor {
    margin-bottom: 500px;
    height: 100vh;
}

.container-login {
    margin-bottom: 300px;
    height: 100vh;
}

.registrer-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    width: 50%;
}

.date-societate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px auto;
    align-items: center;
    width: 50%;
    background-color: rgb(255, 255, 255);
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
    0 6px 20px rgba(0, 0, 0, 0.1);
   
}

.date-societate h4, h5, h6, .group-contact-elements {
    align-self: flex-start;
}

.group-contact-elements {
    margin: 20px 0
}

.item-contact-help {
    margin: 10px 0;
}

.item-contact-help a {
    margin: 10px 0;
    text-decoration: none;
    cursor: pointer;
    color: rgb(22, 199, 158);
}

.input-reg {
    width: 100%;
    border: none;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgb(22, 199, 158);
    padding: 12px 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}





#nume::placeholder {
    color: rgb(126, 126, 126);
    opacity: 0.6;
}

#prenume::placeholder {
    color: rgb(126, 126, 126);
    opacity: 0.6;
}

#email::placeholder {
    color: rgb(126, 126, 126);
    opacity: 0.6;
}

#password::placeholder {
    color: rgb(126, 126, 126);
    opacity: 0.6;
}

#repeatpass::placeholder {
    color: rgb(126, 126, 126);
    opacity: 0.6;
}

.input-reg-submit {
    width: 100%;
    border: none;
    border-radius: 10px;
    background-color: rgb(22, 199, 158);
    color: white;
    padding: 12px 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
        0 6px 20px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}


.input-reg-submit:active {
    transform: translateY(-1px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
                0 12px 40px rgba(0, 0, 0, 0.2);
}


.name-elemnts-form-reg {
    margin: 10px 0;
}

.form-align {
    display: flex;
}




.registrer-title {
    color: rgb(22, 199, 158);
}


.forgot-password {
    width: 90%;
    text-align: right;
    margin: 5px 0;
    color: rgb(22, 199, 158);
    cursor: pointer;
}

.redirect-registrer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0 20px;
}

.redirect-reg-link {
    color: rgb(22, 199, 158);
    cursor: pointer;
    text-decoration: none;

}
.redirect-reg-text {
    color: gray;
    opacity: 0.6;
    margin: 15px 0;


}

.redirect-reg-text-terms {
    color: gray;
    opacity: 1;
    margin: 15px 0;
}

.redirect-reg-terms {
    text-decoration: none;
    color: rgb(22, 199, 158);
    margin: 15px 0;
    opacity: 1;
}

.options-connect {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
    width: 70%;
    margin: 10px auto;
    color: gray;
  
}

.options-connect-sau {
    margin: 20px auto;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect-social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    background-color: rgb(22, 199, 158);
    color: white;
    border-radius: 10px;
    padding: 10px 0;
    cursor: pointer;
}



@media screen and (max-width: 900px) {


    .options-connect {
      
        width: 95%;
      
      
    }

    .registrer-form {
     
        width: 100%;
    }

    .date-societate {
        width: 90%;
    }


    .form-align {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .input-reg {
        width: 90%;
    }

    

    .input-reg-submit {
        width: 90%;
        padding: 12px 0px;
    }
    
}

.success-message {

}
.error-message {
    
}