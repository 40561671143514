
.container-show-lessons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
}

.line-lesson-gackground {
    background-color: white;
    padding: 20px;
    border: 2px solid rgb(218, 225, 234);
    border-radius: 20px;
}

[alt='icon'] {
    widht: 300px;
}

.activate-lesson {
    width: 20%;
    margin: 20px 0;
    filter: grayscale(0%);
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3),
                0 6px 20px rgba(0, 0, 0, 0.2); 
    cursor: pointer;
}

.start-button-disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
.activate-lesson:active {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
    0 12px 40px rgba(0, 0, 0, 0.2);
}

.activate-lesson-gray {
    width: 20%;
    margin: 20px 0;
    opacity: 0.8;
    border-radius: 50%;
    cursor: pointer;
    filter: grayscale(100%);
}

.activate-lesson-gray:active {
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 
    0 12px 40px rgba(0, 0, 0, 0.2);
}




.dimension-headers-lessons {
    width: 35px;
    height: 35px;
    border: 1px solid white;
}



.dimension-headers-lessons-1 {
    width: 30px;
    height: 30px;
}


.dimension-headers-lessons-rotate {
    width: 30px;
    height: 30px;
    rotate: 180deg;
}

.colored-header-lesson {
    background-color: rgb(22, 199, 158);
    margin: 15px 20px;
    max-width: 450px;
    cursor: pointer;
    user-agent: none;

}

.lesson-show-main {
    position: relative; /* Setează ca element de referință */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid rgb(218, 225, 234);
    border-radius: 10px;
    width: 27em;
    margin: 15px 0;
    cursor: pointer;
}




.panel-detail-leson {
     
    position: absolute;
    width: auto; /* Setează lățimea panelului să se potrivească cu lesson-show-main */
    background-color: rgb(22, 199, 158);
    border: 1px solid rgb(218, 225, 234);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10; /* Asigură-te că panelul este deasupra altor elemente */
}


.panel-detail-leson::before {
    content: "";
    position: absolute;
    top: -40px; /* Poziționează săgeata deasupra panoului */
    left: 30px; /* Ajustează această valoare pentru a centra săgeata */
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent rgb(22, 199, 158) transparent; /* Creează săgeata */
}


.lesson-show-main:hover {
    background-color: rgb(243, 248, 254);
}

.item-lesson-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 3px solid rgb(218, 225, 234);
    border-radius: 50%;
}

  
.symbol-lesson {
    padding: 15px 15px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
   
}



.items-text-lesson {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0px 0 0 10px;
    width: 180px;
}



.symbol-item-lesson {
    width: 35px;
    height: 35px;
    color: rgb(22, 199, 158);
    

}


.chapter-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(22, 199, 158);
    margin: 10px 0;
    padding: 15px;
    width: 27em;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.lesson-show-main button {
   
    width: 90%;
    margin: 15px 0;
    background-color: rgb(22, 199, 158);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 20px;
    align-self: center;
}

.obtain-certificate {
    opacity: 0.5; /* Imaginea va fi mai transparentă */
    pointer-events: none; /* Dezactivează interacțiunile */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(248,248,255);
    border: 1px solid gray;
    border-radius: 20px;
    padding: 20px 0;
    height: auto;
    margin: 15px;

}

.obtain-certificate h4 {
    text-align: center;
    margin: 5px 10px;
    width: 80%;
}

.obtain-certificate p {
    text-align: center;
    margin: 10px 10px;
    width: 90%;
}

.obtain-certificate button {
    margin: 10px 0;
    
}

.disabled-image {
    opacity: 0.5; /* Imaginea va fi mai transparentă */
    pointer-events: none; /* Dezactivează interacțiunile */
    width: 50%;
}

.size-container-title-lesson {
    max-width: 300px;
}

.chapter-customize {
    padding: 5px;
    color: white;
    font-weight: 700;
    border: 1px solid white;
    margin: 8px;
    border-radius: 10px;
}

.displaying-show-chapters {
    display: block;
    user-agent: none;

}

.displaying-none-chapters {
    display: none;

}


.dbl-line-horizontal {
    rotate: 90deg;
}


.align-items-lessons-inside-show {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buton-diploma {
    border: none;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(22, 199, 158);
    padding: 10px 20px;
    color: black;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.text-lesson-presentation {
    margin: 10px 40px;
    color: white;
    font-weight: bold;
    text-align: justify;
}


.detail-panel-overlay {
    
    display: flex;
    justify-content: center;
    align-items: center;
  
}


.color-show-lesson-text {
    color: white;
    font-size: 1.2em
}

.detail-panel-content {
    background-color: rgb(22, 199, 158);
    padding: 20px;
    border-radius: 10px;
    width: 410px;
    height: 170px;
    text-align: center;
}

.button-question-show {
    background-color: white;
    border: none;
    border-radius: 10px;
    border: 1px solid white;
    padding: 5px 15px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

.customize-key-lock {
    color: rgb(22, 199, 158);
    width: 15px; /* Dimensiune ajustată */
    height: 15px; /* Dimensiune ajustată */
    margin: 0px;
    border: 1px solid #16c79e;
    border-radius: 50%;
    padding: 10px;
    box-sizing: border-box; /* Include padding și border în dimensiunea totală */
}




.content-panel-details {
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;;
}

.costomize-start-lesson {
    border: none;
    background-color: white;
    border-radius: 10px;
    padding: 8px 0;
    font-weight: bold;
}





@media screen and (max-width: 800px) {

    .activate-lesson-gray {
        width: 15%;
    }

    .activate-lesson {
        width: 15%;
    }

  
}


@media screen and (max-width: 530px) {

    .activate-lesson-gray {
        width: 20%;
    }

    .activate-lesson {
        width: 20%;
    }


    

}





@media screen and (max-width: 475px) {


    .lesson-show-main {
        width: 80vw;
    }


    .chapter-container {
        width: 80vw;
    }

   
    
    .symbol-lesson {
        padding: 15px 5px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
       
    }

    .detail-panel-content {
        width: 75vw;
    }

}




@media screen and (max-width: 400px) {

    .colored-header-lesson {
        width: 90vw;
    }

   
    

}



