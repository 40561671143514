.confirmation-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }
  
  .confirmation-page h2 {
    color: #28a745;
  }
  
  .confirmation-page p {
    margin: 20px 0;
    font-size: 1.1rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  